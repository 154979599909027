/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "申请人姓名",
		dataIndex:"name",
		width:'12%'
	},
	{
		title: "申请人身份",
		key: "tags",
		width: "12%",
		dataIndex: "identity",
		scopedSlots: { customRender: "tags" },
	},
	{
		title: "房屋名称",
		dataIndex: "manageBuildingName",
		scopedSlots: { customRender: "name" },
		width: "20%",
	},
	{
		title: "申请人手机号",
		dataIndex:"tel",
		width:'10%'
	},
	{
		title: "申请人身份证号",
		dataIndex:"idCard",
		width:'14%'
	},
	{
		title: "状态",
		dataIndex:"status",
		width:'10%',
		customRender:function(status){
			switch (status) {
			case 1:
				return '审核中'
			case 2:
				return '已驳回'
			case 3:
				return '已通过'
			default:
				break;
			}
		}
	},
	{
		title: "房屋类型",
		dataIndex:"estateTypeName",
		width:'10%'
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "100",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
// 搜索项
export const searchForm = {
	identity: undefined,
}
export function reset() {
	this.searchForm = {
		identity: undefined,
	}
	this.getData()
}
export function handleTableChange(pagination) {
	const pager = { ...this.pagination };
	pager.current = pagination.current;
	pager.pageSize = pagination.pageSize;
	this.pagination = pager;
	this.getData()
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}
