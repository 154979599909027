<template>
  <div>
    <div class="cardTitle">
      <a-space size="large">
        <span>住户审核</span>
        <a-radio-group
          default-value="1"
          button-style="solid"
          @change="tabsChange"
          v-model="activeName"
        >
          <a-radio-button value="1"> 待审核 </a-radio-button>
          <a-radio-button value="3"> 已通过 </a-radio-button>
          <a-radio-button value="2"> 已驳回 </a-radio-button>
          <a-radio-button> 全部 </a-radio-button>
        </a-radio-group>
      </a-space>
    </div>
    <div class="search-box">
      <a-row>
        <a-col :span="20">
          <a-space size="large">
            <a-select placeholder="请选择用户身份" v-model="searchForm.identity" style="width: 200px" >
              <a-select-option v-for="item in options.identity" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
            </a-select>
            <a-button type="primary" @click="getData()">查 询</a-button>
            <a-button @click="reset">重 置</a-button>
          </a-space>
        </a-col>
      </a-row>
    </div>
    <div class="main">
      <div style="margin-bottom: 16px">
        <!-- 批量操作 -->
        <a-select
          type="primary"
          v-model="activeAction"
          :disabled="!hasSelected"
          :loading="loading"
          style="width: 120px"
          @change="Actions"
          placeholder="请选择操作"
        >
          批量
          <a-select-option v-for="item in ActionsList" :key="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
        <span style="margin-left: 8px">
          <template v-if="hasSelected">
            {{ `已选择 ${selectedRowKeys.length} 条` }}
          </template>
        </span>
      </div>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="tableData"
        :pagination="pagination"
        @change="handleTableChange"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: selectionChoosed,
        }"
        :row-key="
          (record, index) => {
            return record.estateReviewId;
          }
        "
      >
        <span slot="name" slot-scope="text, row">
          {{
            row.buildingName +
            "栋/" +
            row.unitName +
            "单元/" +
            row.estateName +
            "室"
          }}
        </span>
        <span slot="action" slot-scope="text, row">
          <a-space>
            <a class="ant-dropdown-link" @click="audit(row.estateReviewId, 1)" :disabled="row.status!==1"
              >通过</a
            >
            <a class="ant-dropdown-link" @click="audit(row.estateReviewId, 2)" :disabled="row.status!==1"
              >驳回</a
            >
          </a-space>
        </span>
        <span slot="tags" slot-scope="tag">
          <a-tag
            :color="
              tag === 1
                ? 'volcano'
                : tag === 2
                ? 'geekblue'
                : tag === 3
                ? 'geekblue'
                : 'red'
            "
          >
            {{
              tag === 1
                ? "业主"
                : tag === 2
                ? "业主亲属"
                : tag === 3
                ? "租户"
                : tag === 4
                ? "租户亲属"
                : "-"
            }}
          </a-tag>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
import {
	columns,
	pagination,
	searchForm,
	reset,
	handleTableChange,
} from "./depend/config";
import { reviewList, review } from "@/api/basic/resident";
export default {
	data() {
		return {
			pagination: pagination,
			searchForm: searchForm,
			columns: columns,
			activeAction: undefined,
			loading: false,
			// 选择的index
			selectedRowKeys: [],
			tableData: [],
			ActionsList: [
        { value: 1, label: '批量通过'},
        { value: 2, label: '批量驳回'}
      ],
			activeName:'1',
			options:{
				identity:[
					{
						id:1,
						name:'业主'
					},
					{
						id:2,
						name:'业主亲属'
					},
					{
						id:3,
						name:'租户'
					},
					{
						id:4,
						name:'租户亲属'
					},
				]
			}
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			const res = await reviewList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				status:this.activeName,
				...this.searchForm
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total
		},
		tabsChange() {
			this.getData();
		},
		//   审核 1通过 2驳回
		audit(estateReviewId, operate) {
			this.$confirm({
				title: "是否" + (operate === 1 ? "通过" : "驳回"),
				// okText:'删除',
				// cancelText:'取消',
				icon: "form",
				onOk: async() =>{
					const res = await review({
						estateReviewId: estateReviewId,
						operate: operate,
					});
					if (res.code === 200) {
						this.$message.success(res.msg + res.data);
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		handleTableChange:handleTableChange,
		Actions(data) {
      this.selectedRowKeys.forEach(ele => {
          let obj = {operate: data, estateReviewId: ele};
          review(obj).then(res => {
            if(res.code === 200){
							this.$message.success(res.msg)
						}else{
							this.$message.error(res.msg)
						}
          });
      })
			this.activeAction = undefined;
      this.selectedRowKeys = [];
      this.getData();
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		reset: reset,
	},
	computed: {
		// 是否选择selection
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>

<style lang="less" scoped>
</style>